import { GetFullProfile_profiles } from 'operations/GetFullProfile'
import { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'
import { GetReferrals_opportunities_author } from 'operations/GetReferrals'

import Link from 'next/link'
import { FunctionComponent } from 'react'
import { profilesLink } from 'utils'

import { BusinessProfile } from 'components/hooks/useBusinessProfile'
import { Avatar } from 'components/ui'

import { PostOptionalFields } from 'utils/posts/feed'

interface IPostAvatar {
  author:
    | GetReferrals_opportunities_author
    | GetProfileByOwnerId_profiles
    | BusinessProfile
    | GetFullProfile_profiles
    | undefined
    | null
  size?: number
  clickable?: boolean
}

const PostAvatar: FunctionComponent<IPostAvatar> = ({
  author,
  size = 16,
  clickable = true,
}) => {
  return (
    <div>
      {clickable ? (
        <Link href={profilesLink(author!)} passHref prefetch={false}>
          <Avatar
            avatar={
              author?.__typename === 'business_profiles'
                ? author.logo
                : author?.avatar
            }
            layout="fill"
            className="object-cover rounded-full w-"
            alt="Profile image"
            size={size}
          />
        </Link>
      ) : (
        <Avatar
          avatar={
            author?.__typename === 'business_profiles'
              ? author.logo
              : author?.avatar
          }
          layout="fill"
          className="object-cover rounded-full"
          alt="Profile image"
          size={size}
        />
      )}
    </div>
  )
}

export default PostAvatar
