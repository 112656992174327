import {
  GetReferrals_opportunities_author,
  GetReferrals_opportunities_respondants,
} from 'operations/GetReferrals'
import { GetRespondant } from 'operations/GetRespondant'

import {
  M_UPDATE_OPPORTUNITY_FILLED_STATE,
  M_UPDATE_REFERRAL_STATUS,
} from 'lib/mutations/opportunities'
import {
  Q_GET_REFERRAL,
  Q_GET_REFERRALS,
  Q_GET_RESPONDANT,
} from 'lib/queries/opportunities'
import { newReferralData, referralEditedId } from 'lib/reactiveVariables'

import { useMutation, useQuery } from '@apollo/client'
import { EyeIcon, InformationCircleIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import BadgeCheckIcon from 'components/common/badge_check_icon'
import ConfirmationMessageModal from 'components/common/confirmation_message_modal'
import DotsIcon from 'components/common/dots_icon'
import { useProfile } from 'components/hooks'
import { Actions } from 'components/permission_wrapper/utils'
import { ConfirmationModal, Menu, MobileSwitch, Modal } from 'components/ui'

import useDataFetching from '../referrals/edit_referral/hooks/useDataFetching'
import NoReferralInfoModal from '../referrals/no_referral_info_modal'
import RecommendColleagueModal from '../referrals/recommend_colleague/recommend_colleague'
import { ResponseState } from '../referrals/referral'
import { SubmitProfileModal } from '../referrals/submit_profile/submit_profile'
import useDeleteReferral from './hooks/useDeleteReferral'
import useRecommendationResponse from './hooks/useRecommendationResponse'
import useSubmitProfile from './hooks/useSubmitProfile'

const CircleIcon = ({
  children,
  className,
}: {
  children: any
  className?: string
}) => (
  <span
    className={clsx(
      'flex justify-center items-center p-2 w-9 md:w-12 h-9 md:h-12 bg-white rounded-full border border-fourth hover:bg-primary-50',
      className,
    )}
  >
    {children}
  </span>
)
const ReferralOptions = ({
  referralId,
  referralUuid,
  referralTitle,
  referralAuthor,
  responses,
  link,
  isMine,
  isFilled,
  isVisible,
  subcategory,
}: {
  referralId: number
  referralUuid: string
  referralTitle: string
  referralAuthor?: GetReferrals_opportunities_author | null
  responses: GetReferrals_opportunities_respondants[]
  link: string
  isMine: boolean
  isFilled: boolean
  isVisible: boolean
  subcategory: string
}) => {
  const { profile: ownerProfile } = useProfile()
  const {
    acceptRecommendation,
    declineRecommendation,
    showConfirmationModal: showProfileSubmittionConfirmation,
    setShowConfirmationModal: setShowProfileSubmittionConfirmation,
  } = useRecommendationResponse()
  const {
    handleButtonClicked,
    submitProfile,
    withdrawSubmittion,
    showSubmitModal,
    setShowSubmitModal,
    showWithdrawModal,
    setShowWithdrawModal,
    showReferralInfoEmptyModal,
    setShowReferralInfoEmptyModal,
    submitProfileComments,
    setSubmitProfileComments,
  } = useSubmitProfile()

  const {
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
    deleteReferral,
  } = useDeleteReferral()

  const [showRecommendAColleagueModal, setShowRecommendAColleagueModal] =
    useState(false)

  const [showFilledModal, toggleFilledModal] = useState<boolean>(false)

  const router = useRouter()
  const { editReferralData } = useDataFetching({ referralId: referralUuid })

  const handleEditReferral = () => {
    newReferralData(editReferralData)
    referralEditedId(referralId)
    router.push('/opportunities/referrals/edit')
  }

  const { data: respondantData } = useQuery<GetRespondant>(Q_GET_RESPONDANT, {
    variables: {
      fromProfileId: ownerProfile?.id,
      opportunityId: referralId,
    },
    skip: !ownerProfile,
  })

  const [updateReferralStatus] = useMutation(M_UPDATE_REFERRAL_STATUS, {
    refetchQueries: [
      { query: Q_GET_REFERRALS },
      'GetReferrals',
      { query: Q_GET_REFERRAL },
      'GetReferralById',
    ],
  })

  const [updateFilledState] = useMutation(M_UPDATE_OPPORTUNITY_FILLED_STATE, {
    refetchQueries: [
      { query: Q_GET_REFERRALS },
      'GetReferrals',
      { query: Q_GET_REFERRAL },
      'GetReferralById',
    ],
  })

  const hasBeenRecommended = responses.find((resp) => {
    return (
      resp.from_profile_id === ownerProfile?.id &&
      resp.response_state !== ResponseState.DECLINED &&
      resp.response_state !== null
    )
  })

  const isProfileSubmitted =
    respondantData?.opportunity_respondants[0]?.response_type ==
    'SUBMIT_PROFILE'

  const isLookingForClients = subcategory === 'SEEKING_CLIENTS'

  const disableOptions = !isMine && (isMine || isFilled)

  return (
    <>
      <div className="flex gap-4 items-center">
        {isFilled && (
          <MobileSwitch
            mobileView={
              <BadgeCheckIcon className="w-11 h-11 fill-blue-badge-check" />
            }
          />
        )}

        <Link href={link} passHref legacyBehavior>
          <CircleIcon>
            <EyeIcon className="w-6 h-6" />
          </CircleIcon>
        </Link>
        {!isLookingForClients && (
          <Menu
            button={
              <CircleIcon
                className={clsx(
                  disableOptions &&
                    'opacity-50 cursor-not-allowed pointer-events-none',
                )}
              >
                <DotsIcon />
              </CircleIcon>
            }
            options={
              isMine
                ? [
                    {
                      label: isVisible ? 'Hide Referral' : 'Show Referral',
                      onClick: () => {
                        updateReferralStatus({
                          variables: {
                            id: referralId,
                            live: !isVisible,
                          },
                        })
                      },
                    },
                    {
                      label: isFilled ? 'Mark as Open' : 'Mark as Filled',
                      onClick: () => {
                        toggleFilledModal(true)
                      },
                    },
                    {
                      label: 'Edit',
                      onClick: () => handleEditReferral(),
                    },
                    {
                      label: 'Delete',
                      onClick: () => {
                        setShowDeleteConfirmationModal(true)
                      },
                      isDestructive: true,
                    },
                  ]
                : [
                    ...(!hasBeenRecommended
                      ? [
                          {
                            label: isProfileSubmitted
                              ? 'Withdraw My Profile'
                              : 'I can take this referral',
                            onClick: () => {
                              handleButtonClicked(isProfileSubmitted)
                            },
                            permission: 'TakeReferral' as keyof typeof Actions,

                            tooltip:
                              "You don't have permissions to take referrals yet. Your membership application is still being reviewed. Once approved you will have full access. In the meantime you can complete your profile and view different areas of Belongly.",
                          },
                        ]
                      : [
                          {
                            label:
                              hasBeenRecommended?.response_state !==
                              ResponseState.ACCEPTED
                                ? "I'm Interested!"
                                : 'Withdraw My Profile',
                            onClick: () => {
                              acceptRecommendation(
                                hasBeenRecommended?.from_profile_id!,
                                hasBeenRecommended?.opportunity_id!,
                                hasBeenRecommended.response_state as ResponseState,
                              )
                            },
                          },
                        ]),
                    {
                      label: 'Recommend a Colleague',
                      onClick: () => {
                        setShowRecommendAColleagueModal(true)
                      },
                      permission: 'RecommendColleague',
                      tooltip:
                        "You don't have permissions to recommend colleagues",
                    },
                  ]
            }
          />
        )}
      </div>
      <ConfirmationModal
        message={`Are you sure you want to mark this referral as ${
          isFilled ? 'open' : 'filled'
        } ?`}
        isOpen={showFilledModal}
        onClose={() => toggleFilledModal(false)}
        onConfirm={() => {
          updateFilledState({
            variables: {
              id: referralId,
              filled: !isFilled,
            },
          })
          toggleFilledModal(false)
        }}
      />
      <RecommendColleagueModal
        referralId={referralId}
        referralTitle={referralTitle}
        isRecommendColleagueOpen={showRecommendAColleagueModal}
        setIsRecommendColleague={setShowRecommendAColleagueModal}
      />
      <ConfirmationMessageModal
        message="Are you sure you want to withdraw your profile submission?"
        showConfirmation={showProfileSubmittionConfirmation}
        setShowConfirmation={setShowProfileSubmittionConfirmation}
        onClickFunction={() =>
          declineRecommendation(
            hasBeenRecommended?.from_profile_id!,
            hasBeenRecommended?.opportunity_id!,
          )
        }
      />
      <SubmitProfileModal
        showConfirmationSubmitProfile={showSubmitModal}
        setShowConfirmationSubmitProfile={setShowSubmitModal}
        submitProfileComments={submitProfileComments}
        setSubmitProfileComments={setSubmitProfileComments}
        handleSubmitMyProfile={() => submitProfile(referralId, referralAuthor!)}
      />
      <ConfirmationModal
        message="Are you sure you want to withdraw your profile submission?"
        isOpen={showWithdrawModal}
        onClose={() => {
          setShowWithdrawModal(false)
        }}
        onConfirm={() => withdrawSubmittion(referralId)}
      />
      <ConfirmationModal
        message="Are you sure you want to delete this referral?"
        isOpen={showDeleteConfirmationModal}
        onClose={() => {
          setShowDeleteConfirmationModal(false)
        }}
        onConfirm={() => deleteReferral(referralId)}
      />
      <NoReferralInfoModal
        setShowReferralInfoEmpty={setShowReferralInfoEmptyModal}
        showReferralInfoEmpty={showReferralInfoEmptyModal}
      />
    </>
  )
}

export default ReferralOptions
