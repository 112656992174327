import { BusinessProfile } from 'operations/BusinessProfile'
import { GetReferrals_opportunities } from 'operations/GetReferrals'

import PostAvatar from '@/components/home/post/common/post_avatar'
import { parseReferralTitle } from '@/utils/index'
import { DesktopComputerIcon, ReplyIcon } from '@heroicons/react/outline'
import {
  ChatIcon,
  ExclamationCircleIcon,
  LocationMarkerIcon,
  UserIcon,
} from '@heroicons/react/solid'
import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import { useRouter } from 'next/router'
import { isEmpty, isNil } from 'ramda'
import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

import BadgeCheckIcon from 'components/common/badge_check_icon'
import LinkifyWrapper from 'components/common/hocs/LinkifyWrapper'
import { useProfile } from 'components/hooks'
import { Modalities } from 'components/profile/user_basic_details/modality_badge'
import { MobileSwitch, Text } from 'components/ui'

import ReferralOptions from './referral_options'

interface IReferral {
  referral: GetReferrals_opportunities
  business?: BusinessProfile | null
  hasPadding?: boolean
  hasHover?: boolean
}

export const TextIcon = ({
  icon,
  children,
  className,
}: {
  icon: JSX.Element
  children: any
  className?: string
}) => (
  <>
    {/* mobile */}
    <span className={twMerge('flex md:hidden items-center gap-2', className)}>
      {icon}
      <Text variant="body" size="xs">
        {children}
      </Text>
    </span>
    {/* web */}
    <span className={twMerge('hidden sm:flex items-center gap-2', className)}>
      {icon} {<Text variant="body">{children}</Text>}
    </span>
  </>
)

interface IRferralChip {
  label: string
  color?: 'white' | 'purple'
  mobile?: boolean
}

const ReferralChip: FunctionComponent<IRferralChip> = ({
  label,
  color = 'white',
  mobile,
  ...props
}) => (
  <div
    className={twMerge(
      'flex py-2 px-4 text-sm text-gray-500 capitalize bg-white border border-gray-500 rounded-buttons',
      color === 'purple' && 'text-secondary bg-[#dee0ee] border-none',
      mobile && 'min-w-fit',
    )}
    {...props}
  >
    {label}
  </div>
)

const Referral: FunctionComponent<IReferral> = ({
  referral,
  business,
  hasPadding = true,
  hasHover = true,
}) => {
  const router = useRouter()

  const { id: myId } = useProfile()

  const {
    id,
    uuid,
    author,
    created_at: rawCreatedAt,
    title,
    content,
    comments,
    respondants,
    opportunity_respondants_outsiders: outsideRespondants,
    seeking_info: {
      locations,
      sessionModality: modality,
      ageGroups,
      issues,
      interventions,
      mainSpecialties,
      insurances,
    },
    live: isVisible,
    filled: isFilled,
    subcategory,
  } = referral

  const referralAuthor = author ? author : referral?.business

  const authorFullname = author
    ? `${author.first_name} ${author.last_name}`
    : business?.name

  const createdAt = new Date(rawCreatedAt)

  const totalComments = comments.length
  const totalResponses = respondants.length + outsideRespondants.length

  const skills = Array.from(
    new Set([...issues, ...interventions, ...mainSpecialties]),
  )

  const isMyPageReferralAuthor = [
    referral?.business?.administrators?.map((admin) => admin.profile),
    referral?.business?.owner,
  ]
    .flat()
    .some((profile) => profile?.id === myId)

  const isMine = referralAuthor?.id === myId || isMyPageReferralAuthor

  const isUrgent = Boolean(referral?.urgent) || false

  const isLookingForClients = subcategory === 'SEEKING_CLIENTS'

  const iconSize = 'h-6 w-6 stroke-gray-700'
  const referralLink = `/opportunities/referrals/${uuid}/${parseReferralTitle(
    title,
  )}`

  const handleSeeCompleteReferral = () => {
    router.push(referralLink)
  }

  return (
    <div onClick={handleSeeCompleteReferral} className="cursor-pointer">
      <div
        className={clsx(
          'flex flex-col gap-5 py-5 md:p-8 hover:bg-[#f2f0fb]',
          !isVisible && 'opacity-50',
          hasPadding && 'md:px-8',
          hasHover && 'hover:bg-[#f2f0fb]',
        )}
      >
        <section className="flex justify-between px-5">
          <div className="flex gap-3">
            {referralAuthor && (
              <PostAvatar author={referralAuthor} clickable={false} />
            )}
            <div className="flex flex-col self-center">
              <MobileSwitch
                regularView={
                  <Text variant="heading" capitalize>
                    {authorFullname}
                  </Text>
                }
                mobileView={
                  <Text variant="heading" size="md" capitalize>
                    {authorFullname}
                  </Text>
                }
              />
              <Text variant="small" capitalize>
                {formatDistanceToNow(createdAt)} ago
              </Text>
            </div>
            {isFilled && (
              <MobileSwitch
                regularView={
                  <div className="flex gap-2 items-center self-baseline">
                    <BadgeCheckIcon className="w-8 h-8 fill-blue-badge-check" />
                    <Text weight="bold">Filled</Text>
                  </div>
                }
              />
            )}
          </div>
          {referralAuthor && (
            <ReferralOptions
              referralTitle={title}
              referralId={id}
              referralUuid={uuid}
              referralAuthor={author}
              responses={respondants}
              link={referralLink}
              isMine={isMine}
              isFilled={isFilled}
              isVisible={isVisible}
              subcategory={subcategory || ''}
            />
          )}
        </section>
        <section className="flex flex-col gap-4 px-5">
          {isUrgent && (
            <div className="flex gap-2 items-center">
              <ExclamationCircleIcon className="w-6 h-6 fill-error" />
              <Text className="text-error" weight="light" size="sm">
                Marked Urgent
              </Text>
            </div>
          )}
          <Text variant="heading">{title}</Text>
          <Text variant="body" className="break-words line-clamp-3">
            <LinkifyWrapper>{content}</LinkifyWrapper>
          </Text>
        </section>
        <section className="flex justify-between px-5">
          <div className="flex justify-between w-full md:gap-8 md:justify-start">
            {locations?.length > 0 && (
              <TextIcon
                icon={<LocationMarkerIcon className={iconSize} />}
                className="capitalize"
              >
                {isEmpty(locations[0].city)
                  ? locations[0].state
                  : locations[0].city}
                {locations.length > 1 && (
                  <sup className="ml-1">+{locations.length - 1}</sup>
                )}
              </TextIcon>
            )}
            {modality && (
              <TextIcon icon={<DesktopComputerIcon className={iconSize} />}>
                {modality === Modalities.online && 'Online'}
                {modality === Modalities.inperson && 'In-Person'}
                {modality === Modalities.both && 'Online & In-Person'}
              </TextIcon>
            )}
            {!isEmpty(ageGroups) && (
              <TextIcon icon={<UserIcon className={iconSize} />}>
                {ageGroups[0]}{' '}
                {ageGroups.length > 1 && `and ${ageGroups.length - 1}+`}
              </TextIcon>
            )}
          </div>
          <div className="hidden gap-5 md:flex">
            <TextIcon icon={<ChatIcon className={iconSize} />}>
              {totalComments}
            </TextIcon>
            {!isLookingForClients && (
              <TextIcon icon={<ReplyIcon className={iconSize} />}>
                {totalResponses}
              </TextIcon>
            )}
          </div>
        </section>
        {!isEmpty(skills) && (
          <MobileSwitch
            regularView={
              <section className="flex flex-wrap gap-4 mt-4">
                {skills.map((skill) => (
                  <ReferralChip key={skill} label={skill} color="purple" />
                ))}
              </section>
            }
            mobileView={
              <section className="flex overflow-x-auto gap-4 pl-5 max-w-sm scrollbar-hide">
                {skills.map((skill: string) => (
                  <ReferralChip
                    key={skill}
                    label={skill}
                    mobile
                    color="purple"
                  />
                ))}
              </section>
            }
          />
        )}
        {!isEmpty(insurances) && (
          <MobileSwitch
            mobileView={
              <section className="flex overflow-x-auto gap-4 pl-5 max-w-sm scrollbar-hide">
                {insurances.map((insurance: string) => (
                  <ReferralChip
                    key={insurance}
                    label={insurance}
                    mobile
                    color="white"
                  />
                ))}
              </section>
            }
          />
        )}
      </div>
    </div>
  )
}

export default Referral
