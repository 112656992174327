import { Q_GET_REFERRAL } from 'lib/queries/opportunities'
import { ILocation } from 'lib/reactiveVariables'

import { GetReferralById } from '@/operations/GetReferralById'
import { useQuery } from '@apollo/client'

const useDataFetching = ({ referralId }: { referralId: string }) => {
  const { data: opportunitiesData } = useQuery<GetReferralById>(
    Q_GET_REFERRAL,
    {
      variables: { id: referralId },
    },
  )

  const referralData = opportunitiesData?.opportunities[0]

  const editReferralData = {
    referralType: referralData?.subcategory || '',
    referralTitle: referralData?.title || '',
    referralDescription: referralData?.content || '',
    referralSeekingInfo: {
      locations: referralData?.seeking_info.locations as ILocation[],
      typeOfClients: referralData?.seeking_info.typeOfClients as string[],
      genders: referralData?.seeking_info.genders as string[],
      ageGroups: referralData?.seeking_info.ageGroups as string[],
      communities: referralData?.seeking_info.communities as string[],
      insurances: referralData?.seeking_info.insurances as string[],
      sessionModality: referralData?.seeking_info.sessionModality as string,
      languages: referralData?.seeking_info.languages as string[],
      mainSpecialties: referralData?.seeking_info.mainSpecialties as string[],
      interventions: referralData?.seeking_info.interventions as string[],
      issues: referralData?.seeking_info.issues as string[],
    },
    shareReferral: referralData?.share_referral as boolean,
    reason: referralData?.reason ?? null,
    publicDisplay: referralData?.public_display ?? false,
    urgent: referralData?.urgent ?? false,
    assistanceNeeded: referralData?.assistance_needed ?? false,
  }

  return {
    editReferralData,
  }
}

export default useDataFetching
