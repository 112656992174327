import { DeleteReferralById } from 'operations/DeleteReferralById'

import { M_DELETE_REFERRAL } from 'lib/mutations/opportunities'
import { Q_GET_REFERRALS } from 'lib/queries/opportunities'

import { useMutation } from '@apollo/client'
import { useState } from 'react'

const useDeleteReferral = () => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false)

  const [deleteReferralById] = useMutation<DeleteReferralById>(
    M_DELETE_REFERRAL,
    {
      refetchQueries: [{ query: Q_GET_REFERRALS }, 'GetReferrals'],
    },
  )

  const deleteReferral = (referralId: number) => {
    deleteReferralById({
      variables: {
        id: referralId,
      },
    })
      .then(() => {
        setShowDeleteConfirmationModal(false)
      })
      .catch((e) =>
        // eslint-disable-next-line no-console
        console.error('There was an error deleting the referral ' + e.message),
      )
  }

  return {
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
    deleteReferral,
  }
}

export default useDeleteReferral
